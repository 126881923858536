import { param } from 'jquery'
import api from '../../api'

export default {
    CreateWarehouseStocks(params) {
        return api.post('/admin/adminwarehousestocks', params)
    },
    getAllWarehouseStocks(params) {
        return api.get('/admin/getAllWarehouseStocks', { params })
    },
    getInventoryGrouping(params) {
        return api.get('/admin/getInventoryGrouping', { params })
    },
    getTaggedWarehouseStocks(params) {
        return api.get('/admin/getTaggedWarehouseStocks', { params })
    },
    getUntaggedWarehouseStocks(params) {
        return api.get('/admin/getUntaggedWarehouseStocks', { params })
    },
    getWarehouseStockItems(params) {
        return api.get('/admin/getWarehouseStockItems', { params })
    },
    markAsTagged(params) {
        return api.get(`/admin/markAsTagged/${params}`)
    },
    UpdateWarehouseStocks(params) {
        return api.put(`admin/UpdateWarehouseStocks`,params);
    },
    DeleteWarehouseStocks(params) {
        return api.delete(`/admin/adminwarehousestocks/${params}`)
    },
    adminWarehouseItemStocksGet(params){
        return api.get(`/admin/${params.url}`,{params})
    },
    adminWarehouseItemStocksPost(params){
        return api.post(`/admin/${params.url}`,params)
    }
}


import repository from '@/api/modules/Admin/adminEnterBill'
import store from '@/store';
export default {
	state: {
		export: false,
        enter_bill_ref_num:'',
        enter_bill_dialog:false,
        new_enter_bill:false,
		edit_enter_bill_table:{},
		edit_enter_bill_items:[],
		prepared_by: [],
		enter_bill_reload_table: false,
	},
	getters: {
		GET_ENTER_BILL_EXPORT: (state) => state.export,
        GET_ENTER_BILL_REF_NUM:(state)=> state.enter_bill_ref_num,
        GET_ENTER_BILL_DIALOG:(state)=>state.enter_bill_dialog,
        GET_ENTER_BILL_SUCCESS:(state)=>state.new_enter_bill,
		GET_EDIT_ENTER_BILL_TABLE:(state)=>state.edit_enter_bill_table,
		GET_EDIT_ENTER_BILL_ITEMS:(state)=>state.edit_enter_bill_items,
		GET_PREPARED_BY: (state)=>state.prepared_by,
		GET_ENTER_BILL_RELOAD_TABLE: (state) => state.enter_bill_reload_table,
	},
	actions:{
        CreateEnterBill({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.CreateEnterBill(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit('NEW_ENTER_BILL',true)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		UpdateEnterBill({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateEnterBill(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit('NEW_ENTER_BILL',true)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		DeleteEnterBill({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteEnterBill(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit('NEW_ENTER_BILL',true)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		ShowEnterBill({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.ShowEnterBill(payload).then((response)=>{
					if(response.status == 200){
						commit('EDIT_ENTER_BILL_ITEMS',response.data.data);
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        EnterBillGet({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.EnterBillGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        EnterBillPost({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.EnterBillPost(payload).then((response)=>{
					if(response.status == 200){
						commit('ENTER_BILL_RELOAD_TABLE',true);
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		GetPreparedBy({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.EnterBillGet(payload).then((response)=>{
					if(response.status == 200){
						commit('GET_PREPARED_BY',response.data)
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		EnterBillExportDataTable({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.EnterBillExportDataTable(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
	},
	mutations:{
		ENTER_BILL_EXPORT: (state, data) => {
			state.export = data;
		},
        ENTER_BILL_DIALOG:(state,data)=>{
            state.enter_bill_dialog = data
        },
        ENTER_BILL_REF_NUM:(state,data)=>{
            state.enter_bill_ref_num = data
        },
		EDIT_ENTER_BILL_TABLE:(state,data)=>{
			state.edit_enter_bill_table = data
		},
		EDIT_ENTER_BILL_ITEMS:(state,data)=>{
			state.edit_enter_bill_items = data
		},
		NEW_ENTER_BILL:(state,data)=>{
			state.new_enter_bill = data
		},
		GET_PREPARED_BY:(state,data)=>{
			state.prepared_by = data
		},
		ENTER_BILL_RELOAD_TABLE:(state,data)=>{
			state.enter_bill_reload_table = data
		},
		
	},
}

import api from '../../api'

export default {
    getServiceProvider(params) {
        return api.get(`admin/${params.url}`, { params })
    },
    postServiceProvider(params) {
        return api.post(`admin/${params.url}`, params)
    },
    putServiceProvider(params) {
        return api.put(`admin/${params.url}`, params)
    },
    deleteServiceProvider(params) {
        return api.delete(`admin/${params.url}`, params)
    }
}
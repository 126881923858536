export class QueryBuilder {
    constructor(){
        this.whereHas = '';
        this.getColumn = '';
        this.params = {}
        this.hasGet = [];
        this.arr = [];
        this.hasFirst = undefined;
        this.hasCount = undefined;
        this.value = undefined
    }
    table(table){
        this.hasGet = table
        this.arr = table
        return this;
    }
    where(column,operator,value){
        if(operator != undefined && value == undefined){
            value = operator;
            operator = "==";
        }
        else if(operator == undefined && value == undefined){
            value = column
            this.hasFirst = this.hasGet.find(e => e == value);
            this.hasGet = this.hasGet.filter(e => e == value);
            this.value = value
            return this;
        }
        const operators = (val_1, val_2, operator) => {
            switch (operator) {
                case '==':
                    return val_1 == val_2;
                case '===':
                    return val_1 === val_2;
                case '!=':
                    return val_1 != val_2;
                case '!==':
                    return val_1 !== val_2;
                case '<':
                    return val_1 < val_2;
                case '<=':
                    return val_1 <= val_2;
                case '>':
                    return val_1 > val_2;
                case '>=':
                    return val_1 >= val_2;
                case '%':
                    return val_1 % val_2;
                case 'like':
                case 'LIKE':
                    if(val_1){
                        return val_1.toLowerCase().trim().includes(val_2.toLowerCase().trim())
                    }
                default:
                    console.log(`Unsupported operator: ${operator}`);
            }
        };

        this.hasFirst = this.hasGet.find(e => operators(e[column], value, operator));
        this.hasGet = this.hasGet.filter(e => operators(e[column], value, operator));
        this.getColumn = column;
        this.value = value
        return this
    }
    whereIndex(index){
        this.hasFirst = this.hasGet[index]
        return this
    }
    first(val = undefined){
        if(val){
            if(this.hasFirst){
                return this.hasFirst[val];
            }else{
                return this.hasFirst
            }
        }
        else{
            return this.hasFirst
        }
    }
    select(...val){
        this.hasGet = this.hasGet.map(item=>{
            let obj = {};
            val.forEach(el=>{
                obj[el.trim()] = item[el.trim()]
            })
            return obj;
        });
        return this;
    }
    count(){
        return this.get().length
    }
    get(){
        return this.hasGet
    }
    delete(){
        let index = undefined;
        if (this.hasFirst && this.getColumn) {
            if(this.arr.findIndex(item => item[this.getColumn] == this.hasFirst[this.getColumn])){
                index = this.arr.findIndex(item => item[this.getColumn] == this.hasFirst[this.getColumn]);
            }else{
                index = this.arr.indexOf(this.value)
            }
        }
        else{
            index = this.arr.indexOf(this.value)
        }
        if (index !== -1) {
            this.arr.splice(index,1)
        }
        return this.arr;
    }
    orderBy(column,sort){
        this.hasGet = this.hasGet.sort((a,b) =>{
            return a[column] - b[column]
        });
        
        if(sort != undefined && sort.toLowerCase() == 'desc'){
            this.hasGet = this.hasGet.reverse()
        }
        this.hasFirst = this.hasGet[0]
        return this;
    }
    sum(value){
        if(value != undefined || value != ''){
            return this.hasGet.reduce((accumulator, current) => accumulator + parseFloat(current[value]), 0);
        }
    }
    whereNotNull(column){
        this.hasFirst = this.hasGet.find(e=>e[column] != null || e[column] != undefined)
        this.hasGet = this.hasGet.filter(e=>e[column] != null || e[column] != undefined)
        this.getColumn = column
        return this;
    }
    whereNull(column){
        this.hasFirst = this.hasGet.find(e=>e[column] == null || e[column] == undefined)
        this.hasGet = this.hasGet.filter(e=>e[column] == null || e[column] == undefined)
        this.getColumn = column
        return this;
    }
    whereIn(column,value = []){
        let newArray = this.hasGet.filter(e=>value.includes(e[column]))
        let newObj = newArray.find(e=>value.includes(e[column]))
        this.getColumn = column
        this.hasFirst = newObj
        this.hasGet = newArray
        return this;
    }
    whereNotIn(column,value = []){
        let newArray = this.hasGet.filter(e=>!value.includes(e[column]))
        let newObj =   newArray.find(e=>!value.includes(e[column]))
        this.getColumn = column
        this.hasFirst = newObj
        this.hasGet = newArray
        return this;
    }
    pluck(column){
        if(column){
            this.hasGet = this.hasGet.map(e=>e[column])
            return this;
        }
        this.hasGet = newArray
        return this;
    }
    groupBy(...column){
        let _arr = [];
        this.hasGet.reduce((group, item) => {
            const key = column.map(attr => item[attr]).join('-');
                if (!group[key]) {
                  group[key] = [item];
                  _arr.push(item)
                }
            return group;
          },{});
          this.hasGet = _arr;
        return this;
    }
    whereColumn(column,operator,value){
        if(operator != undefined && value == undefined){
            value = operator;
            operator = "==";
        }
        else if(operator == undefined && value == undefined){
            value = column
            this.hasFirst = this.hasGet.find(e => e == value);
            this.hasGet = this.hasGet.filter(e => e == value);
            this.value = value
            return this;
        }
        const operators = (val_1, val_2, operator) => {
            switch (operator) {
                case '==':
                    return val_1 == val_2;
                case '===':
                    return val_1 === val_2;
                case '!=':
                    return val_1 != val_2;
                case '!==':
                    return val_1 !== val_2;
                case '<':
                    return val_1 < val_2;
                case '<=':
                    return val_1 <= val_2;
                case '>':
                    return val_1 > val_2;
                case '>=':
                    return val_1 >= val_2;
                case '%':
                    return val_1 % val_2;
                case 'like':
                case 'LIKE':
                    return val_1.toLowerCase().trim().includes(val_2.toLowerCase().trim())
                default:
                    console.log(`Unsupported operator: ${operator}`);
            }
        };

        this.hasFirst = this.hasGet.find(e => operators(e[column], e[value], operator));
        this.hasGet = this.hasGet.filter(e => operators(e[column], e[value], operator));
        this.getColumn = column;
        this.value = value
        return this
    }
    
}


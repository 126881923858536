import repository from '@/api/modules/Admin/adminFasPurchaseOrder.js'
export default {
	state: {
        admin_faspurchaseorder_dispatch:{},
        admin_faspurchaseorder_status:null,
        new_admin_faspo:false,
        edit_admin_faspo:[],
        edit_fas_po:{},
        fas_po:{},
        fas_po_print:false,
        admin_faspurchaseorder_headers:[],
        admin_approver_capacity: [],
	},
	getters: {
      GET_ADMIN_FASPO_DISPATCH:(state)=> state.admin_faspurchaseorder_dispatch,
      GET_ADMIN_FASPO_STATUS:(state)=> state.admin_faspurchaseorder_status,
      GET_NEW_ADMIN_FASPO:(state)=> state.new_admin_faspo,
      GET_EDIT_ADMIN_FASPO:(state)=> state.edit_admin_faspo,
      GET_EDIT_FAS_PO:(state)=> state.edit_fas_po,
      GET_PRINT_FAS_PO_DETAIL:(state)=>state.fas_po,
      GET_PRINT_FAS_PO:(state)=>state.fas_po_print,
      GET_ADMIN_FASPO_TABLE_HEADERS:(state)=>state.admin_faspurchaseorder_headers,
      GET_ADMIN_APPROVER_CAPACITY: (state)=>state.admin_approver_capacity,
	},
	actions:{
        createFasPO({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.createFasPO(payload).then((response)=>{
                    commit('NEW_ADMIN_FASPO',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         updateFasPO({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updateFasPO(payload).then((response)=>{
                    commit('NEW_ADMIN_FASPO',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         deleteFasPO({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deleteFasPO(payload).then((response)=>{
                    commit('NEW_ADMIN_FASPO',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         getAllFasPurchaseOrder({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAllFasPurchaseOrder(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
	},
	mutations:{
        ADMIN_FASPO_DISPATCH:(state,data)=>{
            state.admin_faspurchaseorder_dispatch = data
        },
        ADMIN_FASPO_STATUS:(state,data)=>{
            state.admin_faspurchaseorder_status = data
        },
        NEW_ADMIN_FASPO:(state,data)=>{
            state.new_admin_faspo = data
        },
        EDIT_ADMIN_FASPO:(state,data)=>{
            state.edit_admin_faspo = data
        },
        EDIT_FAS_PO:(state,data)=>{
            state.edit_fas_po = data
        },
        PRINT_FAS_PO_DETAIL:(state,data)=>{
            state.fas_po = data
        },
        PRINT_FAS_PO:(state,data)=>{
            state.fas_po_print = data
        },
        ADMIN_FASPO_TABLE_HEADERS:(state,data)=>{
            state.admin,_faspurchaseorder_headers = data
        },
        ADMIN_APPROVER_CAPACITY:(state,data)=>{
            state.admin_approver_capacity = data
        },
    },
}

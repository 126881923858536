import repository from "@/api/modules/Admin/adminServiceProvider"

export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        processServiceProvider({commit},payload) {
            return new Promise((resolve, reject) => {
                repository[`${payload.method}ServiceProvider`](payload)
                .then((response) => {
                    if(response.status == 200) {
                        resolve(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },
}
import repository from "@/api/modules/Admin/adminWarehousePreparationForm";

export default {
  state: {
    DATA: [],
    REFRESH: false,
    PRINT: false,
    PRINT_DATA: [],
    SELECTED_TAB: 0,
      
  },
  getters: {
    GET_ADMIN_WPF: (state)=>state,
  },
  actions: {
    adminWarehousePreparationFormGet({commit}, payload) {
      return new Promise((resolve, reject) => {
        repository
        .adminWarehousePreparationFormGet(payload)
        .then((response) => {
          commit('ADMIN_WPF_REFRESH', false)
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    adminWarehousePreparationFormPost({commit}, payload) {
      return new Promise((resolve, reject) => {
        repository
        .adminWarehousePreparationFormPost(payload)
        .then((response) => {
          commit('ADMIN_WPF_REFRESH', true)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
  },
  mutations: {
    ADMIN_WPF_DATA:(state, data)=>{
      state.DATA = data;
    },
    ADMIN_WPF_REFRESH:(state, data)=>{
      state.REFRESH = data;
    },
    ADMIN_WPF_PRINT:(state, data)=>{
      state.PRINT = data;
    },
    ADMIN_WPF_PRINT_DATA:(state, data)=>{
      state.PRINT_DATA = data;
    },
    ADMIN_WPF_SELECTED_TAB:(state, data)=>{
      state.SELECTED_TAB = data;
    },
  },
};

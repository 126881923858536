import repository from "@/api/modules/Accounting/CreditDebitMemo";
import cm_type_repository from "@/api/modules/Accounting/CreditMemoType";
import cm_classification_repository from "@/api/modules/Accounting/CreditMemoClassification";
import dm_type_repository from "@/api/modules/Accounting/DebitMemoType";

export default {
    state: {
        cm_details:[],
        reject_cm:[],
        all_cm:[],
        show_cm:[],
        customer_soas:[],
        customer_payment_detail_files:[],
        credit_memo_files:[],
        debit_memo_files:[],
        dm_details:[],
        reject_dm:[],
        all_dm:[],
        show_dm:[],
        credit_memo_types:[],
        credit_memo_type_status:[],
        credit_memo_classifications:[],
        debit_memo_type:[],
        credit_memo_china_files: [],
        credit_memo_types_card_total: [],
        debit_memo_types_card_total: [],
    },
    getters: {
        // GET_ALL_COST_ITEM: (state) => state.all_cost_item,
        GET_CM_DETAILS: (state) => state.cm_details,
        GET_REJECT_CM: (state) => state.reject_cm,
        GET_ALL_CM: (state) => state.all_cm,
        GET_SHOW_CM: (state) => state.show_cm,
        GET_CUSTOMER_SOAS: (state) => state.customer_soas,
        GET_CUSTOMER_PAYMENT_DETAIL_FILE: (state) => state.customer_payment_detail_files,
        GET_CREDIT_MEMO_FILES: (state) => state.credit_memo_files,
        GET_DEBIT_MEMO_FILES: (state) => state.debit_memo_files,
        GET_DM_DETAILS: (state) => state.dm_details,
        GET_REJECT_DM: (state) => state.reject_dm,
        GET_ALL_DM: (state) => state.all_dm,
        GET_SHOW_DM: (state) => state.show_dm,
        GET_CREDIT_MEMO_TYPES: (state) => state.credit_memo_types,
        GET_CREDIT_MEMO_TYPE_STATUS: (state) => state.credit_memo_type_status,
        GET_CREDIT_MEMO_CLASSIFICATIONS: (state) => state.credit_memo_classifications,
        GET_DEBIT_MEMO_TYPES: (state) => state.debit_memo_type,
        GET_CREDIT_MEMO_CHINA_FILES: (state) => state.credit_memo_china_files,
        GET_ALL_CREDIT_MEMO_TYPES_CARD_TOTAL: (state) => state.credit_memo_types_card_total,
        GET_ALL_DEBIT_MEMO_TYPES_CARD_TOTAL: (state) => state.debit_memo_types_card_total,
        GET_SHOW_CM_CHINA: (state) => state.show_cm_china,
    },
    actions: {
        getCMDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcmdetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CM_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        creditMemoPrint({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.creditmemoprint(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approveCreditMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approvecreditmemo(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rejectCreditMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rejectcreditmemo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REJECT_CM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllCM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallcm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_CM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showCM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.showcm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_CM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postCM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postcm(payload.params,payload.config).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateCM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatecm(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletecm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        cancelCM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cancelcm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerSoas({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomersoas(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_SOAS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerPaymentDetailFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomerpaymentdetailfiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_PAYMENT_DETAIL_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcreditmemofiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CREDIT_MEMO_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDebitMemoFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getdebitmemofiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DEBIT_MEMO_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCustomerPaymentDetailFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletecustomerpaymentdetailfile(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCreditMemoFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletecreditmemofile(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteDebitMemoFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletedebitmemofile(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        printCreditMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.printcreditmemo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDMDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getdmdetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DM_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        printDebitMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.printdebitmemo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approveDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approvedm(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rejectDebitMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rejectdm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REJECT_DM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getalldm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_DM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.showdm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_DM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postdm(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatedm(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletedm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        cancelDM({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.canceldm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemCreditMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getitemcreditmemo(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemDebitMemo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getitemdebitmemo(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoTypes({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getCreditMemoTypes(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_CREDIT_MEMO_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoTypeStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getCreditMemoTypeStatus(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_CREDIT_MEMO_TYPE_STATUS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoType({commit},payload) {
            return new Promise((resolve, reject) => {
                cm_type_repository.getCreditMemoType(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postCreditMemoType({commit},payload) {
            return new Promise((resolve, reject) => {
                cm_type_repository.postCreditMemoType(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        creditMemoClassification({commit},payload) {
            return new Promise((resolve, reject) => {
                cm_classification_repository[payload.action](payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDebitMemoType({commit},payload) {
            return new Promise((resolve, reject) => {
                dm_type_repository.getDebitMemoType(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postDebitMemoType({commit},payload) {
            return new Promise((resolve, reject) => {
                dm_type_repository.postDebitMemoType(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDebitMemoTypes({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getDebitMemoTypes(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_DEBIT_MEMO_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDebitMemoTypesCardTotal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getDebitMemoTypesCardTotal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_DEBIT_MEMO_TYPES_CARD_TOTAL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        sendTechnicalReportEmail({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.sendTechnicalReportEmail(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        generateCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.generateCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        addCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.addCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getAllCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        viewCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.viewCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approveCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approveCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoChinaFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getCreditMemoChinaFiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CREDIT_MEMO_CHINA_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCreditMemoTypesCardTotal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getCreditMemoTypesCardTotal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_CREDIT_MEMO_TYPES_CARD_TOTAL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        disapprovedCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.disapprovedCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showCmChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.showcmchina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_CM_CHINA', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportCreditMemoChina({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.exportCreditMemoChina(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCreditMemoChinaFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deleteCreditMemoChinaFile(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        CM_DETAILS: (state, data) => { (state.cm_details = data) },
        REJECT_CM: (state, data) => { (state.reject_cm = data) },
        ALL_CM: (state, data) => { (state.all_cm = data) },
        SHOW_CM: (state, data) => { (state.show_cm = data) },
        CUSTOMER_SOAS: (state, data) => { (state.customer_soas = data) },
        CUSTOMER_PAYMENT_DETAIL_FILES: (state, data) => { (state.customer_payment_detail_files = data) },
        CREDIT_MEMO_FILES: (state, data) => { (state.credit_memo_files = data) },
        DEBIT_MEMO_FILES: (state, data) => { (state.debit_memo_files = data) },
        DM_DETAILS: (state, data) => { (state.dm_details = data) },
        REJECT_DM: (state, data) => { (state.reject_dm = data) },
        ALL_DM: (state, data) => { (state.all_dm = data) },
        SHOW_DM: (state, data) => { (state.show_dm = data) },
        ALL_CREDIT_MEMO_TYPES: (state, data) => { (state.credit_memo_types = data) },
        ALL_CREDIT_MEMO_TYPE_STATUS: (state, data) => { (state.credit_memo_type_status = data) },
        ALL_CREDIT_MEMO_CLASSIFICATIONS: (state, data) => { (state.credit_memo_classifications = data) },
        ALL_DEBIT_MEMO_TYPES: (state, data) => { (state.debit_memo_type = data) },
        CREDIT_MEMO_CHINA_FILES: (state, data) => { (state.credit_memo_china_files = data) },
        ALL_CREDIT_MEMO_TYPES_CARD_TOTAL: (state, data) => { (state.credit_memo_types_card_total = data) },
        ALL_DEBIT_MEMO_TYPES_CARD_TOTAL: (state, data) => { (state.debit_memo_types_card_total = data) },
        SHOW_CM_CHINA: (state, data) => { (state.show_cm_china = data) },
    }

}

import api from "../../api";

export default {
    adminWarehousePreparationFormGet(params) {
        return api.get(`/admin/${params.url ?? ""}`, { params });
    },
    adminWarehousePreparationFormPost(params) {
        return api.post(`/admin/${params.url ?? ""}`, params);
    },
};

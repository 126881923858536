import repository from "@/api/credentials";

export default {
    state: {
        STATUS: {
            ADMIN_PURCHASE_ORDER_STATUS: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#B59616',value:1, text: "SAVED" },
                { color:'#98B516',value:2, text: "ADMIN" },
                { color:'#6AB516',value:3, text: "EXEC" },
                { color:'#ffa726',value:4, text: "RECEIVING" },
                // { color:'#4db6ac',value:5, text: "ON GOING" },
                { color:'#05BB6E',value:6, text: "FOR PAYMENT" },
                { color:'#25842d',value:7, text: "COMPLETED" },
                // { color:'#00C000',value:8, text: "PAID" },
                { color:'#f09348',value:9, text: "PARTIAL" },
                { color:'#c62828',value:10, text: "CANCELLED" },
                // { color:'#e57373',value:11, text: "OVERDUE" },
                { color:'#4db6ac',value:12, text: "FOR REPRINTING" },
            ],
            ADMIN_JOB_ORDER_STATUS: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#B59616',value:1, text: "SAVED",sereis:1},
                { color:'#98B516',value:3, text: "ADMIN",sereis:2},
                { color:'#6AB516',value:8, text: "EXEC",sereis:3},
                { color:'#05BB6E',value:2, text: "FOR PAYMENT",sereis:4},
                { color:'#4db6ac',value:6, text: "ON GOING",sereis:5},
                { color:'#f09348',value:5, text: "PARTIAL",sereis:6},
                { color:'#00C000',value:4, text: "PAID",sereis:7},
                { color:'#25842d',value:7, text: "COMPLETED",sereis:8},
                { color:'#c62828',value:9, text: "CANCELLED",sereis:9},

            ],
            ADMIN_ENTER_BILL_STATUS: [
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#00C000',value:2, text: "COMPLETED" },
                { color:'#05BB6E',value:4, text: "ON PROCESS" },
                { color:'#B71C1C',value:5, text: "REJECTED" },
                { color:'#c62828',value:6, text: "CANCELLED" },
            ],
            TRANSACTION_JOURNAL_STATUS: [
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#00C000',value:2, text: "PAID" },
                // { color:'#f09348',value:3, text: "PARTIAL" },
                { color:'#6ab516',value:4, text: "APPROVED" },
            ],
            INTERNATIONAL_PAYMENT: [
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#007bff',value:2, text: "APPROVE" },
                { color:'#00C000',value:3, text: "COMPLETED" },
            ],
            RECURRING_BILL_STATUS: [
                { color:'#8A8A8A',value:1, text: "FOR APPROVAL" },
                { color:'#05BB6E',value:2, text: "FOR PAYMENT" },
                { color:'#00C000',value:3, text: "PAID" },
                { color:'#c62828',value:4, text: "OVERDUE" },
            ],
            SERVICE_PAYMENT_REQUEST_STATUS: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#4db6ac',value:5, text: "FOR APPROVAL" },
                { color:'#f09348',value:3, text: "SPECIAL" },
                { color:'#6ab516',value:4, text: "APPROVED" },
                { color:'#00C000',value:2, text: "PAID" },
                { color:'#B71C1C',value:6, text: "REJECTED" },
            ],
            ADMIN_ASSET_TURNOVER_STATUS: [
                { color: '#8A8A8A', value: 1, text: "PENDING" },
                { color: '#6ab516', value: 2, text: "APPROVED" },
            ],
            ITEM_CATEGORY: [
                { value:1, text: "ASSET" },
                { value:2, text: "CONSUMABLE" },
            ],
            BOUND: [
                { value:1, text: "INBOUND" },
                { value:2, text: "OUTBOUND" },
            ],
            STORE_CASH_IN: [
                { value:0, text: "ALL" },
                { value:1, text: "PENDING", color:'#0D6EFD' },
                { value:2, text: "CONFIRMED", color:'#00C000' },
            ],
            JOURNAL_VOUCHER:[
                { value:0, text: "ALL" },
                { value:1, text: "PENDING", color:'#8A8A8A' },
                { value:2, text: "APPROVED", color:'#00C000' },
                { value:3, text: "REJECTED", color:'#B71C1C' },
                { value:4, text: "CANCELLED", color:'#c62828' },
            ],
            ADMIN_TRANSMITTAL: [
                { color: '#8A8A8A', value: 1, text: 'PENDING' },
                { color: '#FFA726', value: 2, text: 'FOR RECEIVING' },
                { color: '#25842D', value: 3, text: 'RECEIVED' },
            ],
            CONSUMABLE_STATUS: [
                { color:'#8A8A8A',value:0, text: "PENDING" },
                { color:'#6ab516',value:1, text: "OUTBOUNDED" },
            ],
            ACTION_TYPE: [
                { value:0, text: "CANCEL", color:'#6ab516' },
                { value:1, text: "REVERT", color:'#6ab516' },
                { value:2, text: "RESET", color:'#6ab516' },
                { value:3, text: "DELETE", color:'#6ab516' },
                { value:4, text: "REJECT", color:'#6ab516' },
            ],
            PAYBILL_ACTION_TYPE: [
                { value:1, text: "REJECT", color:'#B71C1C' },
                { value:2, text: "KEEP", color:'#6ab516' },
            ],
            VENDOR_PAYMENT_DETAIL_STATUS: [
                { value:0, text: "Saved", color:'#6ab516' },
                { value:1, text: "Submitted", color:'#6ab516' },
                { value:2, text: "Approved", color:'#6ab516' },
                { value:3, text: "Rejected", color:'#6ab516' },
                { value:4, text: "Returned", color:'#6ab516' },
                { value:5, text: "Bounced", color:'#6ab516' },
                { value:6, text: "Cancelled", color:'#6ab516' },
                { value:7, text: "Confirmed", color:'#6ab516' },
                { value:8, text: "Exhausted", color:'#6ab516' },
                { value:9, text: "Allocated", color:'#6ab516' },
                { value:10, text: "Partial", color:'#6ab516' },
                { value:11, text: "Replaced", color:'#6ab516' },
                { value:12, text: "Saved", color:'#6ab516' },
            ],
            AS_SUMMARY_STATUS: [
                { color:'#8A8A8A',value:0, text: "PENDING" },
                { color:'#6ab516',value:1, text: "OUTBOUNDED" },
                // { color:'#6ab516',value:3, text: "TURNED OVER" },
            ],
            ADMIN_STOCK_ADJUSTMENT_STATUS: [
                { color:'#9e9e9e',value:0, text: "PENDING" },
                { color:'#ffa726',value:1, text: "FOR APPROVAL" },
                { color:'#25842d',value:2, text: "APPROVED" },
                { color:'#c0ca33',value:3, text: "SPECIAL" },
            ],
            ADMIN_PURCHASE_ORDER_PAYMENT_METHOD: [
                { color:'#25842d', badge_color:'badge-success', value:1, text: "PAYMENT FIRST" },
                { color:'#2196f3', badge_color:'badge-primary', value:2, text: "RECEIVE FIRST" },
                { color:'#9e9e9e', badge_color:'badge-secondary', value:3, text: "PARTIAL PAYMENT" },
                { color:'#f69f1a', badge_color:'badge-warning', value:4, text: "PETTY CASH" },
            ],
            ADMIN_JOB_ORDER_PAYMENT_METHOD: [
                { color:'badge-success', badge_color:'badge-success', value:1, text: "50/50" },
                { color:'badge-primary', badge_color:'badge-primary', value:2, text: "Service before payment" },
                { color:'badge-secondary', badge_color:'badge-secondary', value:3, text: "Payment before service" },
                { color:'badge-warning', badge_color:'badge-warning', value:4, text: "PETTY CASH" },
            ],
            CASH_ADVANCE_STATUS: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#6ab516',value:2, text: "APPROVED" },
            ],
            COMPANY_CLASSIFICATION: [
                { color:'#8A8A8A',value:1, text: "H.O" },
                { color:'#6ab516',value:2, text: "STORES"},
            ],
            PRINT_STATUS: [
                { color:'#007bff',value:1, text: "YES"},
                { color:'#B71C1C',value:0, text: "NO" },
            ],
            ADMIN_FAS_BASKET: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#B59616',value:1, text: "PENDING" },
                { color:'#98B516',value:2, text: "FOR APPROVAL" },
                { color:'#6AB516',value:3, text: "APPROVED" },
                { color:'#ffa726',value:4, text: "PURCHASE ORDER" },
                { color:'#B71C1C',value:5, text: "REJECTED" },
                { color:'#05BB6E',value:6, text: "FOR PAYMENT" },
                { color:'#25842d',value:7, text: "COMPLETED" },
                { color:'#8A8A8A',value:8, text: "FOR OUTBOUND" },
                { color:'#f09348',value:9, text: "PARTIAL" },
            ],
            ADMIN_FAS_REQUISITION: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#B59616',value:1, text: "PENDING" },
                { color:'#98B516',value:2, text: "FOR APPROVAL" },
                { color:'#6AB516',value:3, text: "APPROVED" },
                { color:'#B71C1C',value:4, text: "REJECTED" },
                { color:'#25842d',value:5, text: "COMPLETED" },
                { color:'#05BB6E',value:6, text: "ON PROCESS" },
            ],
            CREDIT_BILL: [
                { color:'#B59616',value:0, text: "ALL" },
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#4db6ac',value:2, text: "FOR APPROVAL" },
                { color:'#6ab516',value:3, text: "APPROVED" },
                { color:'#00C000',value:4, text: "PAID" },
            ],
            FUND_TRANSFER_STATUS: [
                { color:'#8A8A8A',value:1, text: "PENDING" },
                { color:'#00C000',value:2, text: "PAID" },
                { color:'#6ab516',value:4, text: "APPROVED" },
            ],
            ADMIN_ASSET_LDRF_TYPE:[
                { color:'#9e9e9e',value:1, text: "FOR DISPOSAL" },
                { color:'#05BB6E',value:2, text: "REPAIRABLE" },
                { color:'#c62828',value:3, text: "LOST" },
                { color:'#ffa726',value:4, text: "OTHERS" },
            ],
            ADMIN_ASSET_LDRF_TYPE:[
                { color:'#9e9e9e',value:1, text: "FOR DISPOSAL" },
                { color:'#05BB6E',value:2, text: "REPAIRABLE" },
                { color:'#c62828',value:3, text: "LOST" },
                { color:'#ffa726',value:4, text: "OTHERS" },
            ],
            ADMIN_WPF_STATUS:[
                { color:'#B59616',value:0, text: "ALL", access: 'view' },
                { color:'#8A8A8A',value:1, text: "PENDING", access: 'view' },
                { color:'#1e88e5',value:2, text: "FOR APPROVAL", access: 'approve' },
                { color:'#43a047',value:3, text: "APPROVED", access: 'view' },
            ],
        }
    },
    getters: {
        STATUS: (state) => state.STATUS,
    },
    mutations: {

    },
    actions: {
        // renderSideNav({ commit }) {
        //     return new Promise(async (resolve, reject) => {
        //         await repository
        //             .sidenav()
        //             .then((response) => {
        //                 if (response.status == 200) {
        //                     localStorage.setItem(
        //                         "side_menu_bar",
        //                         JSON.stringify(response.data)
        //                     );
        //                     resolve(response);
        //                     commit("SIDENAV_LOAD", response.data);
        //                 }
        //             })
        //             .catch((error) => {
        //                 reject(error);
        //             });
        //     });
        // },
    },
};

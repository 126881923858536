import Vue from "vue";
import Vuex from "vuex";
import Login from './Credentials/login'
import sidenav from './Layouts/sidenav'
import dialog from './Layouts/dialogs'
import DropDownUser from '@/store/Main/DropDowns';
import MainCountries from '@/store/modules/main/Countries';
import MasterDataUsers from '@/store/modules/MasterData/users';
import MasterDataDepartment from '@/store/modules/MasterData/department';
import MasterDataDepartmentAccess from '@/store/modules/MasterData/departmentaccess';
import MasterDataRoles from '@/store/modules/MasterData/roles';
import MasterDataVehicles from '@/store/modules/MasterData/vehicles';
import MasterDataWarehouse from '@/store/modules/MasterData/warehouse';
import MasterDataSalesmanposition from '@/store/modules/MasterData/salesman-position';
import MasterDataSalesman from '@/store/modules/MasterData/salesman';
import MasterDataCustomer from '@/store/modules/MasterData/customer';
import MasterDataVendor from '@/store/modules/MasterData/vendor';
import ItStockAdjustment from '@/store/modules/it/stock-adjustment';
import Containers from '@/store/modules/Operations/Containers/Containers';
import Items from '@/store/modules/Sales/Items/Items';
import SalesReports from '@/store/modules/Reports/SalesReports/index';
import WarehouseInventory from '@/store/modules/Operations/Inventory/Inventory';
import Dispatches from '@/store/modules/Logistics/Dispatches/Dispatches'
import StockTransfer from '@/store/modules/Operations/StockTransfer/StockTransfer'
import POPurchaseOrder from '@/store/modules/Procurement/PurchaseOrder/PurchaseOrder'
import AcctItemCosting from '@/store/modules/Accounting/ItemCosting/ItemCosting'
import StockRequest from '@/store/modules/Operations/StockRequest/StockRequest'
import StockTransferNew from '@/store/modules/Operations/StockTransferNew/StockTransfer'
import AcctStatementOfAccount from '@/store/modules/Accounting/StatementOfAccount/StatementOfAccount'
import AcctCreditMemo from '@/store/modules/Accounting/CreditMemo/CreditMemo'
import accounting from '@/store/modules/Accounting/accounting'
import Payments from '@/store/modules/Accounting/Payments/Payments'
import AcctUnconfirmedPL from '@/store/modules/Accounting/UnconfirmedPackingList/UnconfirmedPackingList'
import Services from '@/store/modules/Services/Services'
import service from '@/store/modules/service'
import dashboard from '@/store/modules/service/dashboard'
import CycleCount from '@/store/modules/Operations/CycleCount/CycleCount'
import ItemJournal from '@/store/modules/Operations/ItemJournal/ItemJournal'
import StockVariance from '@/store/modules/Operations/StockVariance/StockVariance';
import Inbound from '@/store/modules/Operations/Inbound/Inbound';
import Outbound from '@/store/modules/Operations/Outbound/Outbound';
import SystemSettings from '@/store/Main/SystemSettings/index'
import Promos from '@/store/modules/Sales/Promos/Promos';
import salesOrder from '@/store/modules/Sales/SalesOrder/index'
import WPF from '@/store/modules/Operations/WPF/wpf'
import CF from '@/store/modules/Operations/CF/cf'
import PL from '@/store/modules/Sales/PackingList/packing-list'
import salesquotation from '@/store/modules/Sales/SalesQuotations/SalesQuotations'
import StockCards from '@/store/modules/Operations/StockCards/StockCards'
import OrderDelivery from '@/store/modules/Logistics/Delivery/order-delivery'
import WarehouseCalendar from '@/store/modules/Operations/WarehouseCalendar/warehouse-calendar'
import CustomerMaps from '@/store/modules/Maps/customer-maps'
import DashboardSales from '@/store/modules/Sales/Dashboard/dashboard'
import WarehouseUser from '@/store/modules/Operations/WarehouseUser/warehouse-user'
import inventory from '@/store/modules/Operations/index'
import AccountingUser from '@/store/modules/Accounting/AccountingUser/accounting_user'
import AccountingBanks from '@/store/modules/Accounting/Banks/Banks'
import TargetSales from '@/store/modules/TargetSales/TargetSales'
import WarehouseReturn from '@/store/modules/Operations/WarehouseReturn/WarehouseReturn'
import Queueing from '@/store/modules/Operations/Queue/queueing'
import WarehousePersonnel from '@/store/modules/Operations/WarehousePerosonnel/WarehousePersonnel'
import AcctCreditMemoHistory from '@/store/modules/Accounting/CreditMemoHistory/CreditMemoHistory'
import PackingListHistory from '@/store/modules/Accounting/PackingListHistory/PackingListHistory'
import Dashboard from '@/store/modules/Dashboard/Dashboard'
import SalesDashboard from '@/store/modules/Dashboard/Sales'
import AdminDashboard from '@/store/modules/Dashboard/Admin'
import AccountsPayable from '@/store/modules/Accounting/AccountsPayable/AccountsPayable'
import adminOtherTabs from '@/store/modules/Admin/AdminOtherTabs'
import adminAssetManagement from '@/store/modules/Admin/adminAssetManagement'
import HrEmployeeData from '@/store/modules/HR/employeeData/employeeData'
import HrEmployeeDataOtherTabs from '@/store/modules/HR/employeeData/employeeDataOtherTabs'
import adminAccountability from '@/store/modules/Admin/adminAccountability'
import adminAssetCondition from '@/store/modules/Admin/adminAssetCondition'
import adminAssetTurnover from '@/store/modules/Admin/adminAssetTurnover'
import purchaseRequest from '@/store/modules/Admin/purchaseRequest'
import purchaseOrder from '@/store/modules/Admin/purchaseOrder'
import shippingVia from '@/store/modules/Admin/shippingVia'
import purchaseOrderItemsTemplate from '@/store/modules/Admin/purchaseOrderItemsTemplate'
import HRDashboard from '@/store/modules/Dashboard/Hr'
import HrSettings from '@/store/modules/HR/Settings/hrSettings'
import HRLeaves from '@/store/modules/HR/Leaves/hrLeaves'
import HRPlantilla from '@/store/modules/HR/Plantilla/hrPlantilla'
import HROvertime from '@/store/modules/HR/Overtime/hrOvertime'
import HRRecruitment from '@/store/modules/HR/RecruitmentSystem/hrRecruitment'
import dailyManagementSystem from '@/store/modules/Sales/Dms/dms'
import WarehouseDashboard from '@/store/modules/Dashboard/Warehouse';
import AccountingDashboard from '@/store/modules/Dashboard/Accounting'
import adminItem from '@/store/modules/Admin/adminItem'
import adminFasItems from '@/store/modules/Admin/adminFasItems'
import adminFasRequisition from '@/store/modules/Admin/adminFasRequisition'
import adminFasPurchaseOrder from '@/store/modules/Admin/adminFasPurchaseOrder'
import GeneralSettings from '@/store/Main/SystemSettings/GeneralSettings'
import Camera from '@/store/Layouts/camera';
import OnlineTracking from '@/store/modules/Marketing/index'
import Webmail from '@/store/modules/WebMail/webmail'
// import WarehousePersonnel from '@/store/modules/Operations/WarehousePerosonnel/WarehousePersonnel'
// import AcctCreditMemoHistory from '@/store/modules/Accounting/CreditMemoHistory/CreditMemoHistory'
import SampleForm from '@/store/modules/Operations/SampleForm/SampleForm';
import coa from '@/store/modules/Accounting/ChartOfAccounts/coa';
import coatypes from '@/store/modules/Accounting/ChartOfAccountsTypes/coatypes';
import ContainerDiscrepancy from '@/store/modules/Operations/ContainerDiscrepancy/ContainerDiscrepancy'
import Timekeeping from '@/store/modules/HR/Timekeeping/timekeeping'
import TesterCheckList from '@/store/modules/Services/checkList'
import AutoFOC from '@/store/modules/Services/autoFoc'
import Defective from '@/store/modules/Services/defective'
import PartsTransfer from "./modules/PartsTransfer/PartsTransfer";
import Parts from "./modules/Parts/Parts";
import StocksInquiry from '@/store/modules/Sales/StocksInquiry/StocksInquiry'
// import Payroll from '@/store/modules/HR/Payroll/hrPayroll'
import Companies from '@/store/Main/Company/index'
import HRUser from '@/store/modules/HR/HRUser/HRUser'
import MasterDataCluster from '@/store/modules/MasterData/cluster'
import fundamentals from '@/store/modules/Accounting/Fundamentals/fundamentals'
import accountinggroup from '@/store/modules/Accounting/AccountingGroup/accountinggroup'

// import WarehousePersonnel from '@/store/modules/Operations/WarehousePerosonnel/WarehousePersonnel'
// import AcctCreditMemoHistory from '@/store/modules/Accounting/CreditMemoHistory/CreditMemoHistory'
import swalAlert from '@/store/Layouts/swal-alert'
// import dms from '@/store/modules/Sales/Dms/dms'
import journalvoucher from '@/store/modules/Accounting/JournalVoucher/journalvoucher'
import journalvouchermanager from '@/store/modules/Accounting/JournalVoucherManager/journalvouchermanager'
import storebranches from '@/store/modules/MasterData/storebranch'
import generalledger from "./modules/Accounting/GeneralLedgers/generalledger";
import chequevoucher from '@/store/modules/Accounting/ChequeVoucher/chequevoucher';
import sparePartsStagingArea from '@/store/modules/Services/sparePartsStagingArea'
import Disposal from '@/store/modules/Services/disposal'
import StoreBranches from '@/store/modules/Accounting/StoreBranchesAccess/storebranchesaccess'

import AccountingPayBills from '@/store/modules/Accounting/PayBills/PayBills';
import componentSettings from '@/store/Layouts/component-settings';
import adminwarehouse from '@/store/modules/Admin/adminwarehouse';

import enterBill from '@/store/modules/Admin/enterBill';
import repairrequestform from '@/store/modules/Admin/repairrequestform';
import joborder from '@/store/modules/Admin/joborder';
import contractorendorsementform from '@/store/modules/Admin/contractorendorsementform';
import admininventory from '@/store/modules/Admin/admininventory';
import adminwarehousestocks from '@/store/modules/Admin/adminwarehousestocks';
import storeFlow from '@/store/modules/Stores_/StoreFlow';
import status from "./Layouts/status";
import reportmodule from '@/store/modules/MasterData/reportmodule';

import transactionjournal from "./modules/Accounting/TransactionJournal/transactionjournal";
import vatType from '@/store/modules/MasterData/vatType';
import RecurringBill from '@/store/modules/Admin/recurringBill'
import internationalPayment from "./modules/Admin/internationalPayment";
import ServicePaymentRequest from "./modules/Accounting/ServicePaymentRequest/servicepaymentrequest"
import Jobs from "./modules/Accounting/Jobs/jobs";
import adminInboundOutbound from '@/store/modules/Admin/adminInboundOutbound'
import storeCashIn from "./modules/Accounting/StoreCashIn/storecashin";
import adminTransmittal from '@/store/modules/Admin/adminTransmittal';
import System from '@/store/modules/MasterData/system';
import financials from "./modules/Accounting/Financials/financials";
import DebitMemoHistory from "@/store/modules/Accounting/DebitMemooHistory/DebitMemoHistory";
import AccountingDepartment from "@/store/modules/Accounting/AccountingDepartment/accountingdepartment";
import paymentTransmittal from "./modules/Accounting/PaymentTransmittal/paymentTransmittal";
import AccountsPayableSettings from '@/store/modules/MasterData/accountspayablesettings';
import adminStockAdjustment from "./modules/Admin/adminStockAdjustment";

import AdminFasBasket from './modules/Admin/adminFasbasket'
import CashAdvance from "@/store/modules/Accounting/CashAdvance/CashAdvance";
import adminDeliveryReceipt from "./modules/Admin/adminDeliveryReceipt";
import Shared from "./Main/Shared/index";
import creditBill from './modules/Accounting/CreditBill/creditBill'

import Claims from '@/store/modules/Accounting/Claims'
import Currency from '@/store/modules/Accounting/Currency'
import FundTransfer from "./modules/Accounting/FundTransfer/fundtransfer";
import AdminServiceProvider from "./modules/Admin/adminServiceProvider";
import AdminWarehousePreparationForm from '@/store/modules/Admin/adminWarehousePreparationForm';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  modules: {
    Login,
    sidenav,
    MasterDataUsers,
    MasterDataDepartment,
    MasterDataDepartmentAccess,
    MasterDataRoles,
    MasterDataVehicles,
    MasterDataWarehouse,
    MasterDataSalesmanposition,
    MasterDataSalesman,
    DropDownUser,
    MasterDataCustomer,
    MasterDataVendor,
    MainCountries,
    ItStockAdjustment,
    TargetSales,
    dialog,
    Containers,
    Items,
    SalesReports,
    WarehouseInventory,
    Dispatches,
    StockTransfer,
    SalesReports,
    POPurchaseOrder,
    AcctItemCosting,
    StockRequest,
    StockTransferNew,
    AcctStatementOfAccount,
    AcctCreditMemo,
    accounting,
    Payments,
    AcctUnconfirmedPL,
    Services,
    dashboard,
    service,
    CycleCount,
    ItemJournal,
    StockVariance,
    Inbound,
    Outbound,
    SystemSettings,
    Promos,
    salesOrder,
    WPF,
    CF,
    PL,
    Promos,
    salesquotation,
    StockCards,
    OrderDelivery,
    WarehouseCalendar,
    CustomerMaps,
    DashboardSales,
    WarehouseUser,
    AccountingUser,
    AccountingBanks,
    WarehouseReturn,
    inventory,
    WarehousePersonnel,
    AccountsPayable,
    inventory,
    adminOtherTabs,
    adminAssetManagement,
    HrEmployeeData,
    HrEmployeeDataOtherTabs,
    adminAccountability,
    adminAssetCondition,
    adminAssetTurnover,
    purchaseRequest,
    purchaseOrder,
    Dashboard,
    SalesDashboard,
    HRDashboard,
    HrSettings,
    HRLeaves,
    HRPlantilla,
    HROvertime,
    HRRecruitment,
    dailyManagementSystem,
    AcctCreditMemoHistory,
    WarehouseDashboard,
    AccountingDashboard,
    adminItem,
    adminFasItems,
    adminFasRequisition,
    adminFasPurchaseOrder,
    inventory,
    GeneralSettings,
    Camera,
    AdminDashboard,
    Queueing,
    WarehousePersonnel,
    AcctCreditMemoHistory,
    GeneralSettings,
    Camera,
    AdminDashboard,
    OnlineTracking,
    Webmail,
    Queueing,
    PackingListHistory,
    SampleForm,
    ContainerDiscrepancy,
    Timekeeping,
    swalAlert,
    // enterbill,
    // dms,
    coa,
    coatypes,
    journalvoucher,
    journalvouchermanager,
    TesterCheckList,
    AutoFOC,
    PartsTransfer,
    Defective,
    Parts,
    // dms,
    StocksInquiry,
    // Payroll,
    StocksInquiry,
    HRUser,
    sparePartsStagingArea,
    Disposal,
    fundamentals,
    accountinggroup,
    Companies,
    storebranches,
    generalledger,
    chequevoucher,
    MasterDataCluster,
    AccountingPayBills,
    componentSettings,
    transactionjournal,
    adminwarehouse,
    enterBill,
    repairrequestform,
    joborder,
    contractorendorsementform,
    admininventory,
    adminwarehousestocks,
    status,
    reportmodule,
    vatType,
    internationalPayment,
    RecurringBill,
    ServicePaymentRequest,
    shippingVia,
    Jobs,
    adminInboundOutbound,
    storeFlow,
    storeCashIn,
    adminTransmittal,
    purchaseOrderItemsTemplate,
    System,
    financials,
    DebitMemoHistory,
    AccountingDepartment,
    paymentTransmittal,
    AccountsPayableSettings,
    adminStockAdjustment,
    AdminFasBasket,
    CashAdvance,
    adminDeliveryReceipt,
    Shared,
    creditBill,
    Claims,
    Currency,
    FundTransfer,
    AdminServiceProvider,
    AdminWarehousePreparationForm,
  },
});

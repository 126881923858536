import repository from '@/api/credentials';

const routes = [{
    path: '/admin/asset-management/:id',
    name: 'adminassetmanagement',
    component: () => import('@/views/main/modules/Admin/AssetManagement/AssetManagementGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Asset Management',
        }
    },{
        path: '/admin/asset-management-accountability/:id',
        name: 'adminacctabilitysheet',
        component: () => import('@/views/main/modules/Admin/Accountability/AdminAccountabilityGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Accountability Sheet',
        }
    },{
        path: '/admin/purchase-requisition/:id',
        name: 'adminpurchaserequest',
        component: () => import('@/views/main/modules/Admin/PurchaseRequest/PurchaseRequisitionGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Purchase Request',
        }
    },{
        path: '/admin/purchase-order/:id',
        name: 'adminpurchaseorder',
        component: () => import('@/views/main/modules/Admin/PurchaseOrder/PurchaseOrderGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Purchase Order',
        }
    },{
        path: '/admin/items/:id',
        name: 'adminitems',
        component: () => import('@/views/main/modules/Admin/Items/ItemsGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Items',
        }
    },{
        path: '/admin/fas/:id',
        name: 'adminfas',
        component: () => import('@/views/main/modules/Admin/FAS/FasGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'FAS REQUISITION',
        }
    },{
        path: '/admin/fasitems/:id',
        name: 'adminfasitems',
        component: () => import('@/views/main/modules/Admin/FASItems/FasItemsGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'FAS ITEMS',
        }
    },{
        path: '/admin/fas-purchase-order/:id',
        name: 'adminfaspo',
        component: () => import('@/views/main/modules/Admin/FASPurchaseOrder/FasPOGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'FAS PO',
        }
    },{
        path: '/admin/contractor-endorsement/:id',
        name: 'admincontractorendorsement',
        component: () => import('@/views/main/modules/Admin/ContractorEndorsementForm/ContractorEndorsementFormGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Contractor Endorsement',
        }
    },{
        path: '/admin/job-order/:id',
        name: 'adminjoborder',
        component: () => import('@/views/main/modules/Admin/JobOrder/JobOrderGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Job Order',
        }
    },{
        path: '/admin/repair-request-form/:id',
        name: 'adminrrf',
        component: () => import('@/views/main/modules/Admin/RepairRequestForm/RepairRequestFormGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Repair Request Form',
        }
    },{
        path: '/admin/warehouse/:id',
        name: 'adminwarehouse',
        component: () => import('@/views/main/modules/Admin/AdminWarehouse/AdminWarehouseGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Admin Warehouse',
        }
    },{
        path: '/admin/inventory/:id',
        name: 'admininventory',
        component: () => import('@/views/main/modules/Admin/AdminInventory/AdminInventoryGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Admin Inventory',
        }
    },{
        path: '/admin/stocks/:id',
        name: 'adminwarehousestocks',
        component: () => import('@/views/main/modules/Admin/AdminWarehouseStocks/AdminWarehouseStocksGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Admin Warehouse Stocks',
        }
    },{
        path: '/admin/receiving/:id',
        name: 'adminreceiving',
        component: () => import('@/views/main/modules/Admin/Receiving/ReceivingGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Receiving',
        }
    },{
        path: '/admin/enterbills/:id',
        name: 'enterbills',
        component: () => import('@/views/main/modules/Admin/Enterbills/AdminEnterBillsGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Enterbills',
        }
    },{
        path: '/admin/internationalpayment/:id',
        name: 'internationalpayment',
        component: () => import('@/views/main/modules/Admin/InternationalPayment/InternationalPaymentGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'International Payment',
        }
    },{
        path: '/admin/recurringbill/:id',
        name: 'recurringbill',
        component: () => import('@/views/main/modules/Admin/RecurringBill/RecurringBillGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Recurring Bill',
        }
    },{
        path: '/admin/jobs/:id',
        name: 'jobs',
        component: () => import('@/views/main/modules/Admin/Jobs/JobsGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Jobs',
        }
    },{
        path: '/admin/admin-bound/:id',
        name: 'adminbound',
        component: () => import('@/views/main/modules/Admin/Bound/BoundGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'BOUND',
        }
    },{
        path: '/admin/admin-consumable/:id',
        name: 'adminconsumable',
        component: () => import('@/views/main/modules/Admin/AdminItemConsumable/AdminItemConsumableGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ITEM CONSUMABLES',
        }
    },{
        path: '/admin/admin-transmittal/:id',
        name: 'admintransmittal',
        component: () => import('@/views/main/modules/Admin/Transmittal/AdminTransmittalGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ADMIN TRANSMITTAL',
        }
    },{
        path: '/admin/admin-low-value-assets/:id',
        name: 'adminlowvalueassets',
        component: () => import('@/views/main/modules/Admin/AccountabilityLowValueAssets/AccountabilityLowValueAssetsGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'LOW VALUE ASSETS',
        }
    },{
        path: '/admin/admin-stock-adjustments/:id',
        name: 'adminstockadjustments',
        component: () => import('@/views/main/modules/Admin/AdminStockAdjustment/AdminStockAdjustmentGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'STOCK ADJUSTMENT',
        }
    },{
        path: '/admin/admin-fas-basket/:id',
        name: 'adminfasbasket',
        component: () => import('@/views/main/modules/Admin/FASBasket/FasBasketGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Purchase Basket',
        }
    },
    {
        path: '/admin/admin-delivery-addresses/:id',
        name: 'admindeliveryaddresses',
        component: () => import('@/views/main/modules/Admin/AdminDeliveryAddresses/AdminDeliveryAddressesGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'DELIVERY ADDRESSES',
        }
    },
    {
        path: '/admin/admin-asset-basket/:id',
        name: 'adminassetbasket',
        component: () => import('@/views/main/modules/Admin/AssetBasket/AssetBasketGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ASSET BASKET',
        }
    },
    // {
    //     path: '/admin/admin-transactions/:id',
    //     name: 'admintransactions',
    //     component: () => import('@/views/main/modules/Admin/Transactions/AdminTransactionComponent.vue'),
    //     beforeEnter: (to, from, next) => {
    //         repository.checkAccessUser(to.params.id).then((response) => {
    //             if (response.data == 'fail') {
    //                 next('/no-access')
    //             } else {
    //                 next()
    //             }
    //         }).catch(() => {

    //         })
    //     },
    //     meta: {
    //         requiresAuth: true,
    //         title: 'ASSET TRANSACTIONS',
    //     }
    // },
    {
        path: "/admin/admin-outbound/:id",
        name: "adminoutbound",
        component: () => import('@/views/main/modules/Admin/AdminInbound/AdminInboundGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ADMIN OUTBOUND',
        }
    },
    {
        path: "/admin/admin-inbound/:id",
        name: "admininbound",
        component: () => import('@/views/main/modules/Admin/AdminInbound/AdminInboundGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ADMIN INBOUND',
        }
    },
    {
        path: "/admin/admin-asset-migration/:id",
        name: "assetmigration",
        component: () => import('@/views/main/modules/Admin/AssetMigration/AssetMigrationGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ASSET MIGRATION',
        }
    },
    {
        path: "/admin/admin-purchase-item-template/:id",
        name: "adminpurchaseitemtemplate",
        component: () => import('@/views/main/modules/Admin/AdminPurchaseItemTemplate/AdminPurchaseItemTemplateGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'PURCHASE ITEM TEMPLATE',
        },
    },
    {
        path: "/admin/admin-service-provider/:id",
        name: "adminserviceprovider",
        component: () => import('@/views/main/modules/Admin/AdminServiceProvider/AdminServiceProviderGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'SERVICE PROVIDERS',
        }
    },
    {
        path: "/admin/admin-warehouse-preparation-form/:id",
        name: "adminwarehousepreparationform",
        component: () => import('@/views/main/modules/Admin/AdminWarehousePreparationForm/AdminWarehousePrepartionFormGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'ADMIN WAREHOUSE PREPARATION FORM',
        }
    },
];
export default routes;
